
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { VueEditor } from "vue2-editor";
import { Notification } from "@/models/notification.interface";
import Loader from "@/components/General/Loader.vue";

@Component({
  components: { VueEditor, Loader },
})
export default class TermsAndConditions extends mixins(Navigation) {
  loader = false;

  private async created() {
    window.scrollTo(0, 0);
    this.loader = true;
    await this.$store
      .dispatch(
        "settings/getCurrentInformationPage",
        this.$constants.PAGE.TERMS_AND_CONDITIONS
      )
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Pages.TermsAndConditions.error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  private get page() {
    return this.$store.getters["settings/getCurrentInformationPage"];
  }
}
